import { navigate } from "gatsby";
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from "react-helmet";
import { Layout } from '../components';
import { motion } from "framer-motion";
import { API_URL, CHAINS_BY_ID, PERMITTED_CHAIN_ID } from "../constants";
import { useCookie } from "../components/shared/Utils";


/*********************************
 * Utils
 *********************************/
function lockInput(target_selector) {
	let e = document.querySelector(target_selector);
	if (!e) {
		return;
	}
	e.disabled = true;
	e.blur();
}
function lockInputs(target_selectors) {
	if (!('length' in target_selectors)) {
		return;
	}
	for (let i = 0, l = target_selectors.length; i < l; i++) {
		lockInput(target_selectors[i]);
	}
}

function unlockInput(target_selector) {
	if (!target_selector || target_selector.trim() === '') {
		return;
	}
	let e = document.querySelector(target_selector);
	if (!e) {
		return;
	}
	e.disabled = false;
	e.blur();
}
function unlockInputs(target_selectors) {
	if (!('length' in target_selectors)) {
		return;
	}
	for (let i = 0, l = target_selectors.length; i < l; i++) {
		unlockInput(target_selectors[i]);
	}
}

function findObjectByProp(arr, prop, val, strict = true) {
	return arr.find((obj) => {
		// eslint-disable-next-line eqeqeq
		return (strict && obj[prop] === val) || (!strict && obj[prop] == val);
	});
}

function showValidationMessage(text, type = 'error', target_selector, add_preload_to_target = false) {
	if (typeof target_selector === 'undefined') {
		alert(text);
	}
	console.log(target_selector);

	let target = document.querySelector(target_selector);

	if (target && target.nextSibling) {
		add_preload_to_target && target.classList.add('has-preloader');
		!add_preload_to_target && target.classList.remove('has-preloader');
		target.nextSibling.classList.remove('message-type-error');
		target.nextSibling.classList.remove('message-type-success');
		target.nextSibling.classList.remove('message-type-info');
		target.nextSibling.classList.remove('message-type-warning');
		target.nextSibling.classList.add(`message-type-${type}`);
		add_preload_to_target && target.nextSibling.classList.add('has-preloader');
		!add_preload_to_target && target.nextSibling.classList.remove('has-preloader');
		target.nextSibling.innerHTML = text;
	}
}

function clearValidationMessage(target_selector) {
	let target = document.querySelector(target_selector);

	if (target && target.nextSibling) {
		target.classList.remove('has-preloader');
		target.nextSibling.classList.remove('message-type-error');
		target.nextSibling.classList.remove('message-type-success');
		target.nextSibling.classList.remove('message-type-info');
		target.nextSibling.classList.remove('message-type-warning');
		target.nextSibling.classList.remove('has-preloader');
		target.nextSibling.innerHTML = '';
	}
}


// markup
const Login = ({ location }) => {

	const isMobileDevice = useRef(false); /* Check if mobile device */
	const [isPreSale, setIsPreSale] = useState(null);
	const [walletChainId, setWalletChainId] = useState(0);
	const [hasAccount, setHasAccount] = useState(false);
	const [isLogged, setIsLogged] = useState(false);
	const [isWhitelisted, setIsWhitelisted] = useState(null);
	const [isPrevWhitelisted, setIsPrevWhitelisted] = useState(null);
	const [userEmailAddress, setUserEmailAddress] = useState('');
	const [userWalletAddress, setUserWalletAddress] = useState(null);
	const [userToken, setUserToken] = useCookie('userToken', false);
	const [userRefreshToken, setUserRefreshToken] = useState(null);
	const [isSignedUp, setIsSignedUp] = useState(false);


	/*********************************
	 * Check if Mobile Device
	 *********************************/
	useEffect(() => {
		/* Detect if mobile device */
		if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
			// true for mobile device
			isMobileDevice.current = true;
		} else {
			// false for not mobile device
			isMobileDevice.current = false;
		}
	});


	/*********************************
	 * Check if Pre-Sale
	 *********************************/
	useEffect(() => {
		console.clear();
		console.log('Page loaded; starting by checking if there is an active pre-sale...');

		/* Fetching the status of the presale. */
		fetch(`${API_URL}/presale/status`)
			.then((response) => response.json())
			.then((data) => {
				console.log('INITIAL Presale status:', {data});
				setIsPreSale(data.result.status);
			})
			.catch((e) => {
				console.error(e);
			});
	}, []);


	/*********************************
	 * Check token validity
	 *********************************/
	useEffect(() => {
		console.log('Check token validity:', {userToken, userRefreshToken});

		// if we have a token, check if it's valid
		if (userToken != null && userToken !== false && userToken !== 'false' && isLogged !== true && hasAccount !== true/*  && userRefreshToken == null */) {
			fetch(`${API_URL}/auth/me`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					console.log('Token valid; data:', data);
					// if we get a user wallet
					if (data.user && data.user.wallets && data.user.wallets.length) {
						// if we have the user's email
						if (data.user.email) {
							setUserEmailAddress(data.user.email);
						}
						setUserWalletAddress(data.user.wallets[0].address);
						console.log('Setting isWhiteListed:', data.user.whitelisted);
						setIsWhitelisted(data.user.whitelisted);
						console.log('Setting isPrevWhiteListed:', data.user.prev_whitelisted);
						setIsPrevWhitelisted(data.user.prev_whitelisted);
						console.log('Setting isLogged TRUE');
						setIsLogged(true);
						console.log('Setting hasAccount TRUE');
						setHasAccount(true);

						// Refreshing presales to include special ones, if available
						fetch(`${API_URL}/presale/status/logged`, {
							method: 'GET',
							headers: {
								Authorization: `Bearer ${userToken}`,
							},
						})
						.then((response) => response.json())
						.then((data) => {
							console.log('Presale status with pre-existing valid token:', {data});
							setIsPreSale(!!data.result.status);
						})
						.catch((e) => {
							console.error(e);
						});
					} else {
						console.log('Insufficient data, require re-login');
						setUserToken(false);
					}
				})
				.catch((error) => {
					console.error(error);
					setUserToken(false);
				});
		}
	}, [userToken, userRefreshToken]);


	/*********************************
	 * Check account existence
	 *********************************/
	useEffect(() => {
		console.log('Check account existence:', {userWalletAddress, isLogged, hasAccount, userToken});

		if (userWalletAddress == null || isLogged || hasAccount || (userToken != null && userToken !== false && userToken !== 'false')) {
			return;
		}

		// Fetch à API para verificar se utilizador já tem conta
		fetch(`${API_URL}/user/check/wallet/${userWalletAddress}`)
			.then((response) => response.json())
			.then((data) => {
				if (!data.result.valid) {
					throw new Error(`${userWalletAddress} is not a valid address!`);
				}
				if (data.result.exists) {
					setUserEmailAddress(data.result.email);
					setUserWalletAddress(data.result.wallet);

					if (data.result.activated) {
						setHasAccount(true);
						setIsWhitelisted(data.result.whitelisted);
						setIsPrevWhitelisted(data.result.prev_whitelisted);
						loginOnLoad(data.result.wallet);
					} else {
						setIsSignedUp(true);
					}
				}
			})
			.catch((e) => {
				console.error(e);
			});

	}, [userWalletAddress, hasAccount, isLogged, userToken]);

	function loginOnLoad(walletAddress) {
		console.log('loginOnLoad:', {walletAddress});
		showValidationMessage('Requesting Metamask signature...', 'info', '#btn_metamask_login');
		// sign message to get unique signature
		try {
			window.ethereum.request({
				method: 'personal_sign',
				params: [
					walletAddress,
					'🔐  Login @ EphemeralEthernal.com',
				],
			})
			.then((signature) => {
				console.log('personal_sign response:', {signature, walletAddress});
				showValidationMessage('Signing in with Metamask...', 'info', '#btn_metamask_login');

				fetch(`${API_URL}/auth/login`, {
					method: 'POST',
					body: JSON.stringify({
						wallet: walletAddress,
						signature: signature,
					}),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				})
					.then((response) => {
						console.log('Login response:', response);

						return response.json();
					})
					.then((data) => {
						console.log('Login data:', data);

						if (data.error) {
							console.log('Login error:', data.error);
							showValidationMessage(data.error.message, 'error', '#btn_metamask_login');
							console.log('Setting isLogged FALSE');
							setIsLogged(false);
						} else if (data.errors) {
							console.log('Login errors:', data.errors);
							showValidationMessage(data.errors.join('<br>'), 'error', '#btn_metamask_login');
							console.log('Setting isLogged FALSE');
							setIsLogged(false);
						} else {
							console.log('Saving userToken:', {userToken, 'data.token': data.token});
							setUserToken(data.token);
							console.log('Setting isLogged TRUE');
							setIsLogged(true);

							// Refreshing presales to include special ones, if available
							fetch(`${API_URL}/presale/status/logged`, {
								method: 'GET',
								headers: {
									Authorization: `Bearer ${data.token}`,
								},
							})
							.then((response) => response.json())
							.then((data) => {
								console.log('Presale status after login:', {data});
								setIsPreSale(!!data.result.status);
							})
							.catch((e) => {
								console.error(e);
							});
						}
					})
					.catch((error) => {
						showValidationMessage('Error logging in.', 'error', '#btn_metamask_login');
						console.error('Error logging in:', error);
					});
			})
			.catch((e) => {
				console.error('User rejected signing:', e);
				showValidationMessage('🦊&nbsp;&nbsp;Please, sign the request to login with Metamask <span style="font-family:Secondary-Bold">no tx cost</span>.', 'error', '#btn_metamask_login');
				return;
			});
		} catch(error) {
			console.error('Error requesting sign:', error);
			showValidationMessage('Error requesting sign.', 'error', '#btn_metamask_login');
		}
	}


	/*********************************
	 * Metamask: Sign-up
	 *********************************/

	/* Email input */
	function handleInputChange(e) {
		setUserEmailAddress( e.target.value );
	}

	/* Check email */
	function checkSignUpWithMetamask() {

		if (!userEmailAddress || userEmailAddress.trim() === '') {
			return showValidationMessage('Please provide a valid email address', 'error', '#user_email');
		}

		clearValidationMessage('#user_email');
		lockInput('#user_email');

		clearValidationMessage('#btn_metamask');
		lockInput('#btn_metamask');

		showValidationMessage('Checking email address...', 'info', '#user_email');

		// validação do email fazendo chamada à API e verificar callback
		fetch(`${API_URL}/user/check/email/${userEmailAddress}`)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				// Email em formato inválido
				if (!data.result.valid) {
					unlockInput('#user_email');
					unlockInput('#btn_metamask');
					return showValidationMessage('Please provide a valid email address', 'error', '#user_email');
				}

				// Email já utilizado
				if (data.result.valid && data.result.exists) {
					unlockInput('#user_email');
					unlockInput('#btn_metamask');
					return showValidationMessage('Email address already in use', 'error', '#user_email');
				}

				showValidationMessage('Email valid and available', 'success', '#user_email');
				completeSignUpWithMetamask();
			})
			.catch((error) => {
				unlockInput('#user_email');
				unlockInput('#btn_metamask');
				showValidationMessage('An error ocurred. Please try again later', 'error', '#user_email');
				console.error('Error checking email:', error);
			});

	}


	/*********************************
	 * Verify Wallet chain and prompt for connection to Metamask
	 *********************************/
	useEffect(() => {
		console.log('Verify Wallet chain and prompt for connection to Metamask');
		console.log({isPreSale});
		/* if (isPreSale !== true) {
			return;
		} */

		async function promptForConnect() {
			if (window.ethereum) {
				// prompt for connect on enter and save user's first wallet address
				let accounts = await window.ethereum.request({
					method: 'eth_requestAccounts',
				});
				if (accounts && accounts.length) {
					setUserWalletAddress(accounts[0]);
				}

				let _walletChainId = parseInt(window.ethereum.chainId, 16);
				setWalletChainId(_walletChainId);

				if (_walletChainId === PERMITTED_CHAIN_ID) {
					console.log('Wallet is on the right network');
				} else {
					console.log(`You need to change your 🦊 Metamask network to '${CHAINS_BY_ID[PERMITTED_CHAIN_ID]}'`);
				}
			}
		};

		promptForConnect();
	}, [isPreSale]);


	/*********************************
	 * Listen for chainChanged event
	 *********************************/
	useEffect(() => {
		if (window.ethereum) {
			// detect Metamask account change
			/* window.ethereum.on('accountsChanged', function (accounts) {
				console.log('=====> accountsChanges',accounts);
			}); */

			// detect Network account change
			window.ethereum.on('chainChanged', function(networkId){
				console.log('=====> chainChanged',networkId);

				setWalletChainId( parseInt(window.ethereum.chainId, 16) );

				/* if (parseFloat(networkId) === PERMITTED_CHAIN_ID) {
					console.log('Wallet is on the right network');
				} else {
					console.log(`You need to change your 🦊 Metamask network to '${CHAINS_BY_ID[PERMITTED_CHAIN_ID]}'`);
				} */
			});
		} else {
			console.warn('No window.ethereum; chainChanged event NOT registered');
		}
	}, []);


	/*********************************
	 * Prompt for Metamask connect
	 *********************************/
	useEffect(() => {
		console.log('window.ethereum for promptConnect', {'window.ethereum': window.ethereum});
		if (window.ethereum) {
			// prompt for connect on enter and save user's first wallet address
			async function promptConnect() {
				console.log('promptConnect called');
				let accounts;
				try {
					accounts = await window.ethereum.request({
						method: 'eth_requestAccounts',
					});
				} catch (error) {
					console.log('===========> refused Metamask Connect');
					console.warn(error);
				}
				if (accounts && accounts.length) {
					setUserWalletAddress(accounts[0]);
				}
			}
			promptConnect();
		}
	}, []);


	/* Metamask connect */
	function completeSignUpWithMetamask(){
		showValidationMessage('Connecting to your Metamask wallet...', 'info', '#btn_metamask');

		// Where the magic happens
		window.ethereum.request({
			method: 'wallet_switchEthereumChain',
			params: [{ chainId: '0x' + PERMITTED_CHAIN_ID.toString(16) }],
		})
		.then(() => {
			console.log('#1 - Chain changed successfully or already on the permitted Chain');

			window.ethereum.request({
				method: 'eth_requestAccounts'
			})
			.then(accounts => {
				console.log('#2 - Got accounts');

				console.log('accounts', accounts);
				setUserWalletAddress( accounts[0] );
				let walletAddress = accounts[0];

				// sign message to get unique signature
				window.ethereum.request({
					method: 'personal_sign',
					params: [
						walletAddress,
						'🔐  Login @ EphemeralEthernal.com',
					],
				})
				.then((signature) => {
					console.log('personal_sign response:', {signature});
					console.log({userEmailAddress, userWalletAddress, walletAddress});

					showValidationMessage('Completing sign-up...', 'info', '#btn_metamask');

					fetch(`${API_URL}/auth/register`, {
						method: 'POST',
						body: JSON.stringify({
							email: userEmailAddress,
							wallet: walletAddress,
							signature: signature,
						}),
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
						},
					})
						.then((response) => {
							console.log('Register response:', response);

							return response.json();
						})
						.then((data) => {
							console.log('Register data:', data);

							if (data.error) {
								console.log('Register error:', data.error);
								unlockInput('#user_email');
								unlockInput('#btn_metamask');
								showValidationMessage(data.error.message, 'error', '#btn_metamask');
								setIsSignedUp(false);
							} else if (data.errors) {
								console.log('Register errors:', data.errors);
								unlockInput('#user_email');
								unlockInput('#btn_metamask');
								showValidationMessage(data.errors.join('<br>'), 'error', '#btn_metamask');
								setIsSignedUp(false);
							} else {
								// Change layout to "confirm your email" message
								setIsSignedUp(true);
							}
						})
						.catch((error) => {
							unlockInput('#user_email');
							unlockInput('#btn_metamask');
							showValidationMessage('Error registering.', 'error', '#btn_metamask');
							console.error('Error registering:', error);
						});
				})
				.catch((e) => {
					console.log('personal_sign:', e);
					unlockInput('#user_email');
					unlockInput('#btn_metamask');
					showValidationMessage('🦊&nbsp;&nbsp;Please, sign the request to complete sign-up <span style="font-family:Secondary-Bold">no tx cost</span>.', 'error', '#btn_metamask');
					return;
				});

			})
			.catch((e) => {
				console.log('eth_requestAccounts:', e);
				unlockInput('#user_email');
				unlockInput('#btn_metamask');
				showValidationMessage('🦊&nbsp;&nbsp;Please, connect your Metamask wallet.', 'error', '#btn_metamask');
				return;
			});

		})
		.catch((e) => {
			console.log('wallet_switchEthereumChain:', e);
			unlockInput('#user_email');
			unlockInput('#btn_metamask');
			showValidationMessage('🦊&nbsp;&nbsp;Please, connect your Metamask wallet.', 'error', '#btn_metamask');
			return;
		});
	}


	useEffect(() => {
		if (isLogged) {
			console.log({isLogged, isPreSale, isWhitelisted, isPrevWhitelisted});
			if (isPreSale && (isWhitelisted || isPrevWhitelisted)) {
				// alert('Would now redirect to PRE-SALE');
				navigate('/pre-sale/');
			} else {
				// alert('Would now redirect to CLIENT-AREA');
				navigate('/client-area/');
			}
		}
	}, [isLogged, isPreSale]);

	return (
		<Layout location={location}>

			<Helmet>
				<link href="/assets/css/login.css" rel="stylesheet" type="text/css" />
			</Helmet>

				<main data-name="layout" id={'login'}>
					<div className={'main-wrapper'}>

						{ !isSignedUp && hasAccount ? (

							<motion.main
								style={{ opacity:0 }}

								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{
									type: "spring",
									mass: 0.35,
									stiffness: 75,
									duration: 0.5,
									delay: 0.3
								}}
							>
								<div className={'pre-sale-bg d-flex justify-content-center align-items-center'}>
									<div className={'pre-sale-hero container-fluid' + (isSignedUp ? ' logged' : '')}>
										<div className={'col-12 col-sm-8 offset-sm-2 col-xl-6 offset-xl-3'}>
											<div className={'row align-items-center'}>
												{ isPreSale && (isWhitelisted || isPrevWhitelisted) ? (
													<>
														<div className={'title col-6'}>Pre-<br />sale</div>
														<div className={'sign-in col-6'}>Get early access to our artists' exclusive NFT artworks</div>
													</>
												) : (
													<>
														<div className={'title col-6'}>Sign<br />in</div>
														<div className={'sign-in col-6'}>Login to your private area, where you can access your purchases history and status</div>
													</>
												)}
											</div>
										</div>
									</div>
								</div>

								{/* WAITING CALLBACK */}
								<div className={'gradient loading container-fluid'}>
									<div className={'py-5 text-center col-12'}>
										Awaiting your Metamask confirmation to login...
									</div>
									<div className="metamask-container my-5 col-10 col-lg-3">
										<button id="btn_metamask_login" className={'btn btn--primary'} onClick={ () => loginOnLoad(window.ethereum.selectedAddress) } disabled={ !window.ethereum }>
											<img src={'/assets/img/metamask/metamask_logo.svg'} alt={'siteTitle'} />
											<div>Login with<br /><span>METAMASK</span></div>
											<div></div>
										</button>
										<div className="validation-message"></div>
									</div>
								</div>
							</motion.main>

						) : ( isPreSale == null ? (

								<motion.main
									style={{ opacity:0 }}

									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{
										type: "spring",
										mass: 0.35,
										stiffness: 75,
										duration: 0.5,
										delay: 0.3
									}}
								>
									{/* WAITING CALLBACK */}
									<div className={'gradient loading container-fluid'}>
										<div className={'py-5 text-center col-12'}>
											Loading...
										</div>
									</div>
								</motion.main>

							) : (

								<>

									{/* { !isSignedUp && isPreSale && (isWhitelisted || isPrevWhitelisted) ? (
										<div className={'bg-img'}>
											{ isMobileDevice.current ? (
												<video className={'media'} muted loop poster="/assets/vid/pedrita_promo.png">
													<source src={ PromoVid } type="video/mp4" />
													This browser does not support the HTML5 video element.
												</video>
											) : (
												<video className={'media'} muted autoPlay loop poster="/assets/vid/pedrita_promo.png">
													<source src={ PromoVid } type="video/mp4" />
													This browser does not support the HTML5 video element.
												</video>
											)}
											<div className={'bg-fade'}></div>
										</div>
									) : null } */}


									<div className={'pre-sale-bg d-flex justify-content-center align-items-center'}>
										<div className={'pre-sale-hero container-fluid' + (isSignedUp ? ' logged' : '')}>
											<div className={'col-12 col-sm-8 offset-sm-2 col-xl-6 offset-xl-3'}>
												<div className={'row align-items-center'}>
													{ isSignedUp ? (
														isPreSale && (isWhitelisted || isPrevWhitelisted) ? (
															<>
																<div className={'title col-6'}>Pre-<br />sale</div>
																<div className={'sign-in col-6'}>Get early access to our artists' exclusive NFT artworks</div>
															</>
														) : (
															<>
																<div className={'title col-6'}>Sign<br />in</div>
																<div className={'sign-in col-6'}>Login to your private area, where you can access your purchases history and status</div>
															</>
														)
													) : (
														<>
															<div className={'title col-6'}>Sign<br />up</div>
															<div className={'sign-in col-6'}>Get access to a private area, where you can access your purchases history and status</div>
														</>
													)}
												</div>
											</div>
										</div>
									</div>

									{/* { !isSignedUp && isPreSale ? (
										<SlickSlider {...settingsPreSale} className={'instructions-slider w-100'}>

											<div className={'container-fluid'}>
												<div className={'d-flex col-10 col-sm-8 col-xl-6 offset-1 offset-sm-2 offset-xl-3'}>
													<div>1.</div>
													<div className={'instructions'}>
														<h4>Subscribe our newsletter and sign with your wallet</h4>
														<p>Get pre-sale notifications, exclusive news about the project and sign-in into your private area on the website to check your pre-sale status.</p>
													</div>
												</div>
											</div>

											<div className={'container-fluid'}>
												<div className={'d-flex col-10 col-sm-8 col-xl-6 offset-1 offset-sm-2 offset-xl-3'}>
													<div>2.</div>
													<div className={'instructions'}>
														<h4>Choose how many units and pay</h4>
														<p>Choose how many pre-sale artworks you want to buy, until a maximum of 5 and based on a "blind sell" (surprise!) mechanic, and pay to Ephemeral/Ethernal wallet.</p>
													</div>
												</div>
											</div>

											<div className={'container-fluid'}>
												<div className={'d-flex col-10 col-sm-8 col-xl-6 offset-1 offset-sm-2 offset-xl-3'}>
													<div>3.</div>
													<div className={'instructions'}>
														<h4>Receive the NFTs on your wallet (before everyone else)</h4>
														<p>On the day of the drop, your NFT's will be blindly minted and sent to your wallet. We'll notify you by e-mail and status change on your private area on the website.</p>
													</div>
												</div>
											</div>

										</SlickSlider>
									) : null } */}

									{/* If Metamask Connected */}
									{ userWalletAddress && isSignedUp ? (
										<div className={'container-fluid'}>
											<div className={'wallet-connected col-sm-10 offset-sm-1 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4'}>
												<div>👍  Your wallet is now connected</div>
											</div>
										</div>
									) : null }

									{/* If Mobile Device */}
									{isMobileDevice.current && window?.ethereum?.isMetaMask === undefined ? (
										<div className={'container-fluid'}>
											<div className={'info-container mt-4 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3'}>
												<div>
													<img src={'/assets/img/icons/info_icon.svg'} alt={'info-icon'} />
												</div>
												<div>While using a mobile device, you'll have a better experience by using Metamask browser to connect directly. </div>
											</div>
										</div>
									) : null }

									<motion.main
										style={{ opacity:0 }}
										className={'my-4 pb-5'}

										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										transition={{
											type: "spring",
											mass: 0.35,
											stiffness: 75,
											duration: 0.5,
											delay: 1
										}}
									>

										{/* LOGIN / SIGN UP */}
										{ !isSignedUp ? (

											<div className={'container-fluid d-flex justify-content-center align-items-center flex-column mb-5'}>

												<div className={'col-10 col-sm-8 col-md-6 col-xl-4'}>

													<div className={'email-form'}>
														<div className={'email-label'}>1. Sign up with your email *</div>
														<form action="">
															<div className={'input-holder d-flex align-items-center'}>
																<label>
																	<input
																		type="email"
																		id="user_email"
																		name="user_email"
																		placeholder="Your email"
																		onChange={ handleInputChange }
																	/>
																	<div className="validation-message"></div>
																</label>
															</div>
														</form>
													</div>

													<div className={'interest-label mt-4 mb-2'}>2. ETH Wallet Sign-up (no tx cost)</div>

													<div className={'metamask-container col-12'}> {/***** alinhamento texto *****/}

														{window.ethereum && walletChainId !== 0 && walletChainId !== PERMITTED_CHAIN_ID ? (
															<div className="chain-message">{`You need to change your 🦊 Metamask network to '${CHAINS_BY_ID[PERMITTED_CHAIN_ID]}'`}</div>
														) : null}
														<button id="btn_metamask" className={'btn btn--primary'} onClick={ checkSignUpWithMetamask } disabled={ !window.ethereum || (walletChainId !== PERMITTED_CHAIN_ID) }>
															<img src={'/assets/img/metamask/metamask_logo.svg'} alt={'siteTitle'} />
															{window.ethereum ? (
																<>
																	<div>Sign-up with<br /><span>METAMASK</span></div>
																	<div></div>
																</>
															) : (
																<>
																	<div>Please install<br /><span>METAMASK</span></div>
																	<div className={'d-none'}></div>
																</>
															)}
														</button>
														<div className="validation-message"></div>

														{!window.ethereum ? (
															<a className={'download-metamask-url col-12'} href={'https://metamask.io/download.html'} target={'_blank'} rel={'noreferrer'}>
																https://metamask.io/download.html
															</a>
														) : null}

													</div>

													<div className={'d-flex flex-column flex-sm-row'}></div>

													<div className={'privacy-policy mt-4'}>
														By providing your email and wallet you indicate that you have read and accepted our <a href={'/privacy-policy'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a> and <a href={'/terms-of-service'} target={'_blank'} rel={'noreferrer'}>Terms and Conditions</a>.
													</div>
													{/* <div className={'privacy-policy mt-4'}>
														Note: The minting pass will only be awarded to those who buy.
													</div> */}

												</div>

											</div>

										) : (

											<motion.div
												initial={{ opacity: 0 }}
												animate={{ opacity: 1 }}
												exit={{ opacity: 0 }}
												transition={{
													type: "spring",
													mass: 0.35,
													stiffness: 75,
													duration: 0.5
												}}
											>
												{/* AWAITING EMAIL VALIDATION */}
												<div className={'email-validation container-fluid d-flex justify-content-center align-items-center flex-column my-5'}>

													<div className={'message col-10 col-sm-8 col-md-6 col-xl-4'}>
														<img className={'icon-envelope mb-2'} src={'/assets/img/icons/envelope_icon.svg'} alt={'Validate your email'} />
														<div className={'title my-2'}>
															Please complete sign-up by validating your email.
														</div>
														<div className={'sub-title my-2'}>
															Please click the link we have sent to your email address.
														</div>
														<div class="notice">
															If you haven't received any email from us in the next 15 minutes, please check your SPAM folder.
														</div>
													</div>

													<div className={'separator col-10 col-sm-8 col-md-6 col-xl-4'}>&nbsp;</div>

													<div className={'summary col-10 col-sm-8 col-md-6 col-xl-4'}>
														<div className={'title my-2'}>Your input was:</div>
														<div className={'email-address my-2'}>
															<span className={'sub'}>Email:</span> <span className={''}>{ userEmailAddress }</span> <span className={'waiting-validation'}>(awaiting validation)</span>
														</div>
														<div className={'wallet-address my-2'}>
															<span className={'sub'}>Wallet:</span> <span className={''}>{ userWalletAddress }</span>
														</div>
													</div>

												</div>
											</motion.div>

										) }

									</motion.main>

								</>

							)
						) }

					</div>

				</main>

		</Layout >
	)
}

export default Login
